// V3 styled components collection
import styled from 'styled-components';
import {
  Button as ButtonV2,
  colors as colorsV2,
  mobileThresholdPixels,
  Container as ContainerV2,
  FormInput,
} from '../v2/StyledComponents';

export const fontSizes = {
  xs: '12px',
  s: '15px',
  m: '18px',
  l: '24px',
  xl: '30px',
  x2l: '36px',
  x3l: '46px',
  x4l: '50px',
};

export const colors = {
  ...colorsV2,
  blueGrey: '#DFE4EF',
  darkBlueGrey: '#7F8FA4',
  lightGrey2: '#E5E5E5',
  lightGrey3: '#C4C4C4',
  lightGrey4: 'rgba(23, 44, 66, 0.4)',
  red: '#FF0000',
  popup: 'rgba(23, 44, 66, 0.72)',
  opacityFilter: 'rgba(0, 0, 0, 0.25)',
  myAccountMenu: 'rgba(0, 0, 0, 0.1)',
  whiteTransparent: 'rgba(255,255,255,0.95)',
};

export const Title1 = styled.h1`
  color: ${colors.navy};
  font-weight: bold;
  line-height: ${fontSizes.x4l};
  font-size: ${props => props.fontSize || fontSizes.x3l};
  ${props => !props.lowercase && 'text-transform: uppercase'};
  text-align: center;
  font-family: Gotham;
  font-style: normal;
  margin: 0px;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: ${fontSizes.xl};
    line-height: ${fontSizes.x2l};
  }
`;

export const Title2 = styled.h2`
  font-family: Gotham;
  font-weight: 500;
  line-height: 30px;
  font-size: 32px;
  text-align: center;
  color: ${colors.navy};
  margin: 0px 0px 20px;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: ${fontSizes.l};
    margin: 0px 15px;
  }
`;

export const Subtitle2 = styled.span`
  font-family: Roboto;
  font-size: ${fontSizes.m};
  line-height: 23px;
  color: ${colors.navy};
  text-align: center;
  margin: -10px 0px 20px;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 14px;
    margin: 0px 0px 20px;
    line-height: 19px;
  }
`;

export const StyledTitle2 = styled(Title2)`
  font-size: 36px;
  @media (max-width: ${mobileThresholdPixels}) {
    font-size: 26px;
  }
`;

export const Note = styled.p`
  font-family: Roboto;
  color: ${colors.navy};
  line-height: 20px;
  font-size: ${fontSizes.s};
  margin-top: 20px;

  @media (max-width: ${mobileThresholdPixels}) {
    text-align: center;
    font-size: ${fontSizes.xs};
    margin-top: 10px;
    margin-bottom: 25px;
  }
`;

export const Container = styled(ContainerV2)`
  background-color: ${colors.lightGrey};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 954px;
  padding: 15px;
  margin: 64px auto ${props => props.smallMarginBottom ? '80' : '120'}px;
  align-items: center;
  @media (max-width: ${mobileThresholdPixels}) {
    margin: 30px 0px 60px;
    ${props => props.smallMarginBottom && 'margin-bottom: 45px;'}
    max-width: 100vw;
    box-sizing: border-box;
  }
`;

export const ComponentsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  @media (max-width: ${mobileThresholdPixels}) {
    flex-direction: column;
    align-items: center;
  }
`;


export const Button = styled(ButtonV2)`
  text-transform: none;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: ${fontSizes.s};
    min-height: 45px;
    min-width: 220px;
    padding: 12px;
  }
`;

export { mobileThresholdPixels, FormInput };
